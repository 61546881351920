<template>
  <div class="main_content">
    <el-page-header
      class="page_section_card"
      @back="goBack"
      content="TikTok搜索设置"
    ></el-page-header>
    <searchConditionComp
      v-loading="loading"
      type="tiktok"
      :options="searchConditionsOptions"
      @getContentConditions="getContentConditions"
    ></searchConditionComp>
    <el-card class="page_section_card">
      <div slot="header" class="clearfix">
        <span>其他搜索</span>
      </div>
      <el-table border :data="noContentConditions">
        <el-table-column label="类型" prop="title"></el-table-column>
        <el-table-column label="开关" prop="status">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card>
      <el-button type="primary" @click="saveHandle">保存</el-button>
    </el-card>
  </div>
</template>
<script>
import searchConditionComp from "@/views/settingViews/searchSetting/comps/searchConditionComp";
export default {
  components: {
    searchConditionComp,
  },
  data() {
    return {
      searchConditionsOptions: [],
      noContentConditions: [],
      loading: false,
      allCountrys: [],
      allCategories: [],
    };
  },
  created() {
    this.searchConditionsOptions = [];
    this.noContentConditions = [];
    this.getSetting();
  },
  methods: {
    getContentConditions(data) {
      console.log(data);
      this.searchConditionsOptions = data;
    },
    goBack() {
      this.$router.go(-1);
    },
    getSetting() {
      this.loading = true;
      this.axios("get", `${this.$baseUrl}v1/TiktokSearch/lists`, {
        type: "tiktok",
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 1) {
            res.data.forEach((item) => {
              if (
                item.keyword === "country" ||
                item.keyword === "followers" ||
                item.keyword === "category"
              ) {
                if (item.keyword === "country") {
                  item.content = item.content.map((country) => {
                    country.name = country.country_name;
                    return country;
                  });
                } else if (item.keyword === "category") {
                  item.content = item.content.map((category) => {
                    return {
                      name: category,
                      id: category
                    };
                  });
                }
                this.searchConditionsOptions.push(item);
              } else {
                this.noContentConditions.push(item);
              }
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    saveHandle() {
      let arr = this.searchConditionsOptions.concat(this.noContentConditions);
      arr = JSON.parse(JSON.stringify(arr));
      arr = arr.map((item) => {
        if (item.content && item.content.length > 0) {
          item.content = item.content.map((i) => {
            return i.id;
          });
          return item;
        } else {
          return item;
        }
      });
      this.axios(
        "POST",
        `${this.$baseUrl}v1/TiktokSearch/insertSearch`,
        arr
      ).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.goBack();
        }
      });
    },
  },
};
</script>